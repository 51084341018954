import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthConstants } from 'src/app/config/auth-constants';
import { User } from 'src/app/services/user/model/user';
import { CompanyFull } from '../company/model/company-full';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';
import { ToastService } from '../toast/toast.service';
import { TokenService } from './../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData$ = new BehaviorSubject<any>('');

  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
    private toastService: ToastService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  getUserData() {
    this.storageService.get(AuthConstants.AUTH).then((res) => {
      this.userData$.next(res);
    });
  }

  login(postData: any): Promise<any> {
    const user: User = new User();
    return new Promise((resolve, reject) => {
      this.httpService.post('auth', postData).subscribe({
        next: async (res: any) => {
          if (res.user) {
            user.fromJson(res.user);
            this.storageService.store(AuthConstants.AUTH, user);
            this.storageService.store(AuthConstants.TOKEN, res.tokenRow);
            this.userData$.next(user);
            resolve(true);
          }
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao fazer seu login.';
          reject(msg);
        },
      });
    });
  }

  signUp(user: User): Observable<any> {
    return this.httpService.post('auth/sign-up', { user });
  }

  signUpCompany(user: User, company: CompanyFull): Observable<any> {
    return this.httpService.post('auth/company-user/sign-up', {
      user,
      address: user?.address,
      city: user?.address?.city,
      company,
      category: company.category,
      niche: company.niche,
    });
  }

  logout(navigateToLogin: boolean) {
    this.storageService.clear().then(async () => {
      this.userData$.next('');
      this.tokenService.token$.next('');

      if (navigateToLogin) {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['']);
      }
    });
  }

  forgot(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`macros/password-recovery/${email}/`).subscribe({
        next: (res: any) => {
          if (res?.ok) {
            resolve(true);
          } else {
            reject();
          }
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao tentar recuperar sua senha.';
          this.toastService.presentToastError(msg);
          reject(msg);
        },
      });
    });
  }

  changePassword(email: string, token: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`macros/password-recovery/${email}/`, { password, token })
        .subscribe({
          next: (res: any) => {
            if (res?.ok) {
              resolve(true);
            } else {
              reject();
            }
          },
          error: (error) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao fazer seu logn.';
            this.toastService.presentToastError(msg);
            reject(msg);
          },
        });
    });
  }

  goToHomePage() {
    this.router.navigate(['home/feed']);
  }
}
